import React, { useEffect } from 'react';
import cryptoRandomString from 'crypto-random-string';

import { api, auth, publicUrl } from '../config';
import { setToken } from '../auth';

const LoginPage = () => {
  useEffect(() => {
    const { location } = window;

    const token = new URLSearchParams(location.search).get('token');
    if (token) {
      setToken(token);
      location.assign(publicUrl);
    } else {
      const stateId = cryptoRandomString({ length: 10 });

      const state = Buffer.from(
        JSON.stringify({
          stateId,
          redirectUri: publicUrl + '/login',
        }),
      ).toString('base64');

      const redirectUrl = encodeURIComponent(api.callbackUrl);
      const url = `${auth.ssoUrl}/oauth2/auth?client_id=${auth.clientId}&redirect_uri=${redirectUrl}&response_type=code&state=${state}`;
      location.assign(url);
    }
  });

  return <div>Logging in...</div>;
};

export default LoginPage;
