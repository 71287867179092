export const statuses = {
  active: 'Разрешен',
  blocked: 'Блокиран',
  partially_blocked: 'Частично блокиран',
};

export const getStatusLevelChoices = Object.keys(statuses).map((key) => ({
  id: key,
  name: statuses[key],
}));

export const getAccessLevelLabel = (status) => statuses[status];
