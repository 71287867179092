import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, CardContent, Box } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Loading, DateField, Error, useQuery } from 'react-admin';

const useAsideStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    height: '100%',
  },
}));

const Aside = ({ record }) => {
  const classes = useAsideStyles();

  return (
    <div className={classes.root}>{record && <List shopId={record.id} />}</div>
  );
};

const List = ({ shopId }) => {
  const { loading, error, data } = useQuery({
    type: 'getOne',
    resource: 'activity',
    payload: { id: shopId },
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    data && (
      <Box m="0 0 1em 1em">
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Активност
            </Typography>
            <Box display="flex">
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>Първа</Typography>
                    <DateField record={data} source="firstActivityAt" />
                  </Box>
                </Box>
              </Box>
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>Последна</Typography>
                    <DateField record={data} source="lastActivityAt" />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Typography variant="h6" gutterBottom>
              Методи
            </Typography>
            {data.methods && (
              <Box
                height={Math.max((data.methods.length + 1) * 58, 400)}
                mb="1em"
              >
                <DataGrid
                  rows={data.methods}
                  columns={[
                    { field: 'name', headerName: 'Име', width: 250 },
                    { field: 'count', headerName: 'Брой', width: 100 },
                  ]}
                  hideFooterPagination
                  disableSelectionOnClick
                  disableColumnMenu
                />
              </Box>
            )}
            <Typography variant="h6" gutterBottom>
              IP Адреси
            </Typography>
            {data.ips && (
              <Box height={Math.max(((data?.ips.length ?? 0) + 1) * 58, 300)}>
                <DataGrid
                  rows={data.ips.map((ip) => ({ id: ip, domain: '' }))}
                  columns={[
                    { field: 'id', headerName: 'IP', width: 140 },
                    { field: 'domain', headerName: 'Домейн', width: 200 },
                  ]}
                  hideFooterPagination
                  disableSelectionOnClick
                  disableColumnMenu
                />
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    )
  );
};

export default Aside;
