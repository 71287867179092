import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#003d85',
    },
    grey: {
      300: '#ebebeb',
    },
    info: {
      main: '#bad0ff',
    },
    error: {
      main: '#e41e1a',
    },
  },
});

export default theme;
