const tokenKey = 'token';

export const getToken = () => localStorage.getItem(tokenKey);

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(tokenKey, token);
  }
};

export const clearToken = () => localStorage.removeItem(tokenKey);
