import { getToken, clearToken } from './token';
import { auth, publicUrl } from '../config';

const checkAuth = () => {
  return getToken() ? Promise.resolve() : Promise.reject();
};

const logout = () => {
  clearToken();

  const url = `${auth.ssoUrl}/oauth2/logout?client_id=${auth.clientId}&redirect_uri=${publicUrl}`;
  window.location.assign(url);

  return Promise.resolve();
};

const checkError = (error) => {
  const status = error.status;
  if (status === 401 || status === 403) {
    clearToken();
    return Promise.reject();
  }
  return Promise.resolve();
};

const getPermissions = () => Promise.resolve();

const authProvider = {
  checkAuth,
  checkError,
  getPermissions,
  logout,
};

export default authProvider;
