import React from 'react';
import { SelectInput, CheckboxGroupInput, useInput } from 'react-admin';
import { isEqual, difference } from 'lodash';
import { getStatusLevelChoices } from './access-utils';

const idAllowed = 'active';
const idBlocked = 'blocked';
const idPartial = 'partially_blocked';

const allowAll = [{ Effect: 'allow', Action: ['*'] }];
const denyAll = [{ Effect: 'deny', Action: ['*'] }];
const partial = [
  { Effect: 'allow', Action: ['*'] },
  { Effect: 'deny', Action: [] },
];

const methods = [
  'api:CreateLabel',
  'api:CheckAddress',
  'api:GetPrice',
  'api:GetCities',
  'api:GetCountries',
  'api:GetOffices',
  'api:GetQuarters',
  'api:GetStreets',
  'api:GetTrace',
];

// Converts policies in JSON format to access level
// e.g
// [ { Effect: 'allow', Action: ['*']}] => active
const formatAccessLevel = (accessPolicies) => {
  if (isEqual(accessPolicies, allowAll)) {
    return idAllowed;
  }

  if (isEqual(accessPolicies, denyAll)) {
    return idBlocked;
  }

  return idPartial;
};

// Converts access level to JSON policies
// e.g
// active => [ { Effect: 'allow', Action: ['*']}]
const parseAccessLevel = (inputValue) => {
  if (inputValue === idAllowed) {
    return allowAll;
  }
  if (inputValue === idBlocked) {
    return denyAll;
  }

  return partial;
};

// Converts policies in JSON format to selected access methods
// e.g
// [ { Effect: 'deny', Action: ['method1', 'method2']] => ['method1', 'method2']
const formatMethods = (accessPolicies) => {
  const { Action: deniedMethods } = accessPolicies.find(
    (p) => p.Effect === 'deny',
  );
  return difference(methods, deniedMethods);
};

// Converts selected access methods to policies in JSON format
// e.g
// ['method1', 'method2'] => [ { Effect: 'allow': Action: ['*] },{ Effect: 'deny', Action: ['method1', 'method2']]
const parseMethods = (selectedMethods) => [
  allowAll[0],
  {
    Effect: 'deny',
    Action: difference(methods, selectedMethods),
  },
];

const ManageAccessInput = (props) => {
  const {
    input: { value },
  } = useInput(props);

  const selectedAccessLevel = formatAccessLevel(value);

  return (
    <>
      <SelectInput
        allowEmpty={false}
        source="accessPolicies"
        format={formatAccessLevel}
        parse={parseAccessLevel}
        choices={getStatusLevelChoices}
      />
      {selectedAccessLevel === idPartial && (
        <CheckboxGroupInput
          source="accessPolicies"
          choices={methods.map((method) => ({ id: method, name: method }))}
          format={formatMethods}
          parse={parseMethods}
        />
      )}
    </>
  );
};

export default ManageAccessInput;
