import React from 'react';
import { useListContext } from 'react-admin';
import get from 'lodash/get';
import toString from 'lodash/toString';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { sanitizeFieldRestProps } from 'ra-ui-materialui';
import Highlighter from 'react-highlight-words';

const useStyles = makeStyles(
  (theme) => ({
    highlight: {
      backgroundColor: theme.palette.info.main,
      fontWeight: 500,
    },
  }),
  { name: 'HighligthFilterQueryTextField' },
);

export const HighligthFilterQueryTextField = ({
  className,
  source,
  record = {},
  filterKey = 'q',
  ...rest
}) => {
  const value = toString(get(record, source));
  const highlightText = useListContext()?.filterValues?.[filterKey];

  const classes = useStyles(rest);

  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
      {...sanitizeFieldRestProps(rest)}
    >
      <Highlighter
        highlightClassName={classes.highlight}
        searchWords={[highlightText]}
        autoEscape={true}
        textToHighlight={value}
      />
    </Typography>
  );
};
