import React from 'react';
import Star from '@material-ui/icons/Star';
import StarHalf from '@material-ui/icons/StarHalf';
import StarBorder from '@material-ui/icons/StarBorder';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { meanBy } from 'lodash';

const useStyles = makeStyles({
  root: {
    opacity: 0.87,
    whiteSpace: 'nowrap',
    display: 'flex',
  },
  large: {
    width: 20,
    height: 20,
  },
  small: {
    width: 15,
    height: 15,
  },
});

const maxRating = 6;

const iconFromIndex = (index, rating) => {
  if (index < rating) {
    return rating - index >= 1 ? Star : StarHalf;
  }
  return StarBorder;
};

export const StarRatingField = ({ record, source, size = 'large' }) => {
  const classes = useStyles();

  const { ratings } = record;
  const rating = Math.round(meanBy(ratings, (r) => r.rating) * 100) / 100;

  return (
    <Tooltip title={rating} arrow>
      <span className={classes.root}>
        {Array(maxRating)
          .fill(true)
          .map((_, i) => {
            const Icon = iconFromIndex(i, rating);
            return (
              <Icon
                key={i}
                className={size === 'large' ? classes.large : classes.small}
              />
            );
          })}
      </span>
    </Tooltip>
  );
};
