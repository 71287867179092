export const rules = [
  { id: 'getOffices', name: 'Ном. офиси' },
  { id: 'checkAddress', name: 'Адресна валидация' },
  { id: 'deliveryMethod', name: 'Метод на доставка' },
  { id: 'packageReturnInfo', name: 'Указ. връщане на пратка' },
  { id: 'recipientInfo', name: 'Инфо. получател' },
  { id: 'shipmentNotification', name: 'Известие за пратка' },
  { id: 'shipmentReview', name: 'Проверка на пратка' },
  { id: 'getTracking', name: 'Проследяване на пратка' },
];
