import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useCheckboxStyles = makeStyles(
  () => ({
    root: {
      padding: 0,
    },
  }),
  { name: 'TooltipCheckbox' },
);

const useListItemStyles = makeStyles(
  () => ({
    dense: {
      padding: 0,
    },
  }),
  { name: 'TooltipListItem' },
);

const useListItemTextStyles = makeStyles(
  () => ({
    root: {
      margin: 1,
    },
  }),
  { name: 'TooltipListItemText' },
);

const RatingTooltip = ({ active, payload, ...rest }) => {
  const checkBoxClasses = useCheckboxStyles(rest);
  const listItemClasses = useListItemStyles(rest);
  const listItemTextClasses = useListItemTextStyles(rest);

  if (active && payload?.[0]) {
    const {
      value,
      payload: { rules },
    } = payload?.[0];

    return (
      <Card variant="outlined">
        <CardContent>
          <Typography>
            Rating: <b>{value}</b>
          </Typography>
          <List dense>
            {rules.map((rule) => (
              <ListItem key={rule} disabled classes={listItemClasses}>
                <Checkbox
                  edge="start"
                  checked={rule.result}
                  size="small"
                  classes={checkBoxClasses}
                />
                <ListItemText
                  primary={rule.name}
                  classes={listItemTextClasses}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    );
  }

  return null;
};

const RatingsChart = ({ record: { ratings } }) => (
  <div style={{ width: '100%', height: 300 }}>
    <ResponsiveContainer>
      <LineChart data={ratings}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="date" name="Date" />
        <YAxis dataKey="rating" domain={[0, 6]} name="Rating" tickCount={7} />
        <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
        <Tooltip cursor={{ strokeDasharray: '3 3' }} content={RatingTooltip} />
        <Line
          type="monotone"
          dataKey="rating"
          stroke="#8884d8"
          strokeWidth={2}
          fill="url(#colorUv)"
        />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

export default RatingsChart;
