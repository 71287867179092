import { cleanEnv, str, url } from 'envalid';

const env = cleanEnv(process.env, {
  REACT_APP_API_URL: str({
    devDefault: '',
    default: 'https://k0406608uc.execute-api.eu-central-1.amazonaws.com/prod',
    desc: `This is the URL for the backend API.
    When running locally and not overwritten by .env file,
    a proxy will be used to connect to the locally running backend server.`,
  }),
  REACT_APP_API_CALLBACK_URL: url({
    desc: 'This is the SSO callback URL needed for login',
    default:
      'https://k0406608uc.execute-api.eu-central-1.amazonaws.com/prod/auth/callback',
    devDefault: 'http://localhost:3001/auth/callback',
  }),
  REACT_APP_AUTH_SSO_URL: url({
    desc: 'This is the **single sign-on** URL.',
    default: 'https://login.econt-bg.com',
    devDefault: 'https://login.econt-bg.com',
  }),
  REACT_APP_AUTH_CLIENT_ID: str({
    desc: 'This is the **Client ID** for the Econt SSO.',
    default: 'TS5BdXQNT',
    devDefault: 'w4WDF5sSY',
  }),
  REACT_APP_PUBLIC_URL: url({
    desc: 'This is public URL of the dashboard.',
    default: 'https://apilibrary.econt.com',
    devDefault: 'http://localhost:3000',
  }),
});

export const api = {
  url: env.REACT_APP_API_URL,
  callbackUrl: env.REACT_APP_API_CALLBACK_URL,
};

export const auth = {
  ssoUrl: env.REACT_APP_AUTH_SSO_URL,
  clientId: env.REACT_APP_AUTH_CLIENT_ID,
};

export const publicUrl = env.REACT_APP_PUBLIC_URL;
