import React from 'react';
import { Admin, Resource } from 'react-admin';

import theme from './theme';
import authProvider from './auth/auth-provider';
import dataProvider from './data-provider';
import onlineShops from './resources/online-shops';
import { LoginPage } from './pages';

const App = () => (
  <Admin
    loginPage={LoginPage}
    authProvider={authProvider}
    dataProvider={dataProvider}
    theme={theme}
    title="Econt API Библиотека"
  >
    <Resource
      name="onlineShops"
      options={{ label: 'Магазини' }}
      {...onlineShops}
    />
    <Resource name="apiVersions" />
    <Resource name="shops" />
    <Resource name="mediators" />
    <Resource name="clientSoftware" />
    <Resource name="ratings" />
    <Resource name="activity" />
  </Admin>
);

export default App;
