import React from 'react';
import {
  ChipField,
  Datagrid,
  DateInput,
  EditButton,
  Filter,
  List,
  ReferenceArrayInput,
  SearchInput,
  SelectArrayInput,
  SelectInput,
  FunctionField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import {
  ChipArrayField,
  HighligthFilterQueryTextField,
  StarRatingField,
} from '../../components';
import { getAccessLevelLabel, getStatusLevelChoices } from './access-utils';
import ListActions from '../../components/list-actions';

const useStyles = makeStyles((theme) => ({
  listItem: {
    marginRight: theme.spacing(1),
  },
  arrayInputRoot: {
    minWidth: 150,
  },
}));

const useSelectClasses = makeStyles(() => ({
  chip: {
    height: 'auto',
    margin: '0 2px 0 0',
  },
}));

const nameSortAsc = { field: 'name', order: 'ASC' };
const arrayFilters = [
  { label: 'Протокол', source: 'apiVersion', reference: 'apiVersions' },
  {
    label: 'Клиентски софтуер',
    source: 'clientSoftware',
    reference: 'clientSoftware',
  },
  { label: 'Медиатор', source: 'mediator', reference: 'mediators' },
];

const ListFilters = (props) => {
  const styles = useStyles(props);
  const selectClasses = useSelectClasses(props);

  return (
    <Filter {...props}>
      <DateInput source="timestamp_gte" label="От" alwaysOn />
      <DateInput source="timestamp_lte" label="До" alwaysOn />
      <SearchInput
        source="q"
        alwaysOn
        inputProps={{ placeholder: 'Търговец, Потр. име, ЕИК' }}
      />
      <SelectInput
        source="approved"
        label="Одобрен"
        alwaysOn
        choices={[
          { id: 0, name: 'Не' },
          { id: 1, name: 'Да' },
        ]}
      />
      <SelectInput
        source="status"
        label="Достъп"
        alwaysOn
        choices={getStatusLevelChoices}
      />
      {arrayFilters.map((props) => (
        <ReferenceArrayInput
          {...props}
          key={props.source}
          sort={nameSortAsc}
          perPage={100}
          className={styles.arrayInputRoot}
        >
          <SelectArrayInput
            optionText="name"
            translateChoice={false}
            classes={selectClasses}
          />
        </ReferenceArrayInput>
      ))}
    </Filter>
  );
};

const OnlineShopsList = (props) => {
  return (
    <List
      {...props}
      filters={<ListFilters />}
      actions={<ListActions />}
      title="Онлайн магазини"
      bulkActionButtons={false}
    >
      <Datagrid>
        <HighligthFilterQueryTextField source="merchantName" label="Търговец" />
        <HighligthFilterQueryTextField
          source="merchantUsername"
          label="Потр. име"
        />
        <HighligthFilterQueryTextField source="name" label="Магазин" />
        <HighligthFilterQueryTextField source="url" label="URL" />
        <ChipArrayField label="ЕИК" source="eiks" />
        <StarRatingField source="rating" />
        <ChipField label="Протокол" source="apiVersion" />
        <ChipField label="Клиентски софтуер" source="clientSoftware" />
        <ChipField label="Медиатор" source="mediator" />
        <FunctionField
          label="Одобрен"
          render={({ approved }) => `${approved ? 'Да' : 'Не'}`}
        />
        <FunctionField
          label="Достъп"
          render={({ status }) => getAccessLevelLabel(status)}
        />
        <EditButton label="" />
      </Datagrid>
    </List>
  );
};

export default OnlineShopsList;
