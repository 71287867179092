import StoreIcon from '@material-ui/icons/Store';

import OnlineShopsList from './list';
import OnlineShopEdit from './edit';

export default {
  list: OnlineShopsList,
  edit: OnlineShopEdit,
  icon: StoreIcon,
};
