import { fetchUtils } from 'react-admin';

import { getToken } from './token';

const httpClient = (url, options = {}) => {
  const token = getToken();
  if (token) {
    options.user = {
      authenticated: true,
      token: `Bearer ${token}`,
    };
    options.credentials = 'include';
  }
  return fetchUtils.fetchJson(url, options);
};

export default httpClient;
