import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'ra-ui-materialui';

const useStyles = makeStyles({
  chip: { margin: 4, cursor: 'inherit' },
});

const maxItemsCount = 3;

export const ChipArrayField = (props) => {
  const { record, source } = props;
  const classes = useStyles(props);

  const [collapsed, setCollapsed] = useState(true);

  const allItems = record[source];
  const itemsToDisplay = collapsed
    ? allItems?.slice(0, maxItemsCount)
    : allItems;

  return (
    <>
      {itemsToDisplay &&
        itemsToDisplay.map((item) => (
          <Chip className={classes.chip} label={item} key={item} />
        ))}
      {allItems && allItems.length > maxItemsCount && (
        <Button
          label={collapsed ? 'Покажи всички' : 'Покажи по-малко'}
          onClick={() => setCollapsed(!collapsed)}
        />
      )}
    </>
  );
};
ChipArrayField.defaultProps = { addLabel: true };

export const TextArrayField = (props) => {
  const { record, source } = props;

  return (
    <>
      {record[source].map((item) => (
        <Typography component="span" variant="body2" key={item}>
          {item}
        </Typography>
      ))}
    </>
  );
};
