import React from 'react';
import {
  CheckboxGroupInput,
  Edit,
  FormWithRedirect,
  ReferenceField,
  SaveButton,
  TextField,
  TextInput,
  Toolbar,
  BooleanInput,
  FunctionField,
} from 'react-admin';

import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import Aside from './aside';
import RatingsChart from './ratings-chart';
import { rules } from './rules';
import ManageAccessInput from './manage-access-input';
import { getAccessLevelLabel } from './access-utils';

const OnlineShopEdit = (props) => {
  const { record } = props;

  return (
    <Edit
      title={record?.name}
      aside={<Aside />}
      undoable={false}
      component="div"
      {...props}
    >
      <OnlineShopForm />
    </Edit>
  );
};

const OnlineShopForm = ({ record, basePath, ...props }) => (
  <Card>
    <CardContent>
      <Box display="flex" flexDirection="column" mb="1rem">
        <Grid container spacing={3}>
          <Grid item>
            <Box flexGrow={1}>
              <Typography variant="h6">Търговец</Typography>
              <TextField record={record} source="merchantName" />
            </Box>
          </Grid>
          <Grid item>
            <Box flexGrow={1}>
              <Typography variant="h6">Потр. име</Typography>
              <TextField record={record} source="merchantUsername" />
            </Box>
          </Grid>
          <Grid item>
            <Box flexGrow={1}>
              <Typography variant="h6">Статус</Typography>
              <FunctionField
                record={record}
                render={({ status }) => getAccessLevelLabel(status)}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box flexGrow={1}>
              <Typography variant="h6">Протокол</Typography>
              <TextField record={record} source="apiVersion" />
            </Box>
          </Grid>
          <Grid item>
            <Box flexGrow={1}>
              <Typography variant="h6">Медиатор</Typography>
              <TextField record={record} source="mediator" />
            </Box>
          </Grid>
          <Grid item>
            <Box flexGrow={1}>
              <Typography variant="h6">Клиентски софтуер</Typography>
              <TextField record={record} source="clientSoftware" />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <FormWithRedirect
          {...props}
          record={record}
          redirect={false}
          render={(formProps) => (
            <form>
              <Box p="1em">
                <Box display="flex">
                  <Box flex={1} mr="2em">
                    <Box flex={1} mr="0.5em">
                      <BooleanInput
                        label="Одобрен"
                        source="approved"
                        fullWidth={true}
                      />
                    </Box>
                    <Box flex={1} mr="0.5em">
                      <TextInput
                        source="name"
                        titlе="Име"
                        fullWidth={true}
                        disabled={record.apiVersion === 'econtdelivery'}
                      />
                    </Box>
                    <Box flex={1} mr="0.5em">
                      <TextInput
                        source="url"
                        title="URL"
                        fullWidth={true}
                        disabled={record.apiVersion === 'econtdelivery'}
                      />
                    </Box>
                    <Box flex={1} mr="1em">
                      <ManageAccessInput source="accessPolicies" />
                    </Box>
                  </Box>
                  <Box flex={1} ml="1em">
                    <Typography variant="h5" gutterBottom>
                      Изключени правила
                    </Typography>
                    <CheckboxGroupInput
                      source="rulesIgnoreList"
                      margin="none"
                      choices={rules}
                      row={false}
                    />
                  </Box>
                </Box>
              </Box>
              <Toolbar>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <SaveButton
                    saving={formProps.saving}
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect
                    }
                  />
                </Box>
              </Toolbar>
              <Box display="flex" flexDirection="column">
                <Typography variant="h6" gutterBottom>
                  Рейтинг
                </Typography>
                <Grid container>
                  <ReferenceField
                    record={record}
                    basePath={basePath}
                    reference="ratings"
                    source="id"
                    link={false}
                  >
                    <RatingsChart />
                  </ReferenceField>
                </Grid>
              </Box>
            </form>
          )}
        />
      </Box>
    </CardContent>
  </Card>
);

export default OnlineShopEdit;
